<template>
    <div class="dot">
        <van-nav-bar
                title="客户经理绑定"
                :border="true"
        />
        <van-form @submit="onSubmit">
            <van-field
                    input-align="right"
                    :value="dotText"
                    readonly
                    clickable
                    required
                    name="DotId"
                    label="受理网点"
                    placeholder="点击选择受理网点"
                    @click="dotShowPicker = true"
                    :rules="[{ required: true, message: '请选择受理网点' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="dotShowPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="dotColumns"
                        @confirm="dotConfirm"
                        @cancel="dotShowPicker = false"
                />
            </van-popup>
            <van-field
                    input-align="right"
                    :value="sysUserText"
                    readonly
                    clickable
                    required
                    name="SystemUserId"
                    label="客户经理"
                    placeholder="点击选择客户经理"
                    @click="sysUserShowPicker = true"
                    :rules="[{ required: true, message: '请选择客户经理' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="sysUserShowPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="sysUserColumns"
                        @confirm="sysUserConfirm"
                        @cancel="sysUserShowPicker = false"
                />
            </van-popup>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import {bindsave, getloadselect} from '@/api/public'
    import {Toast} from 'vant';

    export default {
        name: 'fangdai',
        data() {
            return {
                formData: {
                    DotId: '',
                    SystemUserId: '',
                },
                dotText: '',
                dotShowPicker: false,
                dotColumns: [],
                sysUserText: '',
                sysUserShowPicker: false,
                sysUserColumns: [{text: '请先选择受理网点', disabled: true},],
            }
        },
        created() {
            this.getloadselect();
        },
        methods: {
            getloadselect() {
                getloadselect().then((res) => {
                    this.dotColumns = res.data.list;
                    this.dotColumns.forEach((dot) => {
                        dot.text = dot.dotName;
                        dot.users.forEach((user) => {
                            user.text = user.name;
                        });
                    });
                }).catch(e => {
                    console.log(e);
                })
            },
            dotConfirm(value, index) {
                this.dotText = value.text;
                this.formData.DotId = value.id;
                this.sysUserColumns = this.dotColumns[index].users;
                this.position = this.dotColumns[index].address;
                this.dotShowPicker = false;
            },
            sysUserConfirm(value) {
                this.sysUserText = value.text;
                this.formData.SystemUserId = value.id;
                this.sysUserShowPicker = false;
            },
            onSubmit() {
                bindsave(this.formData).then((res) => {
                    Toast.success(res.msg);
                    WeixinJSBridge.call('closeWindow'); // eslint-disable-line
                }).catch(e => {
                    console.log(e);
                })
            },
        }
    }
</script>
<style lang="less">
    .dot {
        .van-field__error-message {
            text-align: right;
        }
    }
</style>


